$(document).on('mouseenter', '.star', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var hovered = $(this).index();
	$('.star', form).each(function( key, value ){
		if ( key <= hovered ) {
			$(value).addClass('active');
		}
	});
});
$(document).on('mouseleave', '.star', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var selected = $('input[type=radio][name^=rating]:checked', form);
	if ( selected.length > 0 ) {
		var selectedIndex = selected.closest('.star').index();
		$('.star', form).each(function( key, value ){
			if ( key <= selectedIndex ) {
				$(value).addClass('active');
			}
		});
	}
});
$(document).on('change', 'input[type=radio][name^=rating]', function(){
	var form = $(this).closest('form');
	$('.star', form).removeClass('active');
	var selected = $('input[type=radio][name^=rating]:checked', form);
	if ( selected.length > 0 ) {
		var selectedIndex = selected.closest('.star').index();
		$('.star', form).each(function( key, value ){
			if ( key <= selectedIndex ) {
				$(value).addClass('active');
			}
		});
		$('input[name=rating]', form).val(selected.val());
	}
});
$(document).on('change', '.aditional-rate input', function(){
	var label = $(this).data('label');
	$(this).closest('.row').find('.aditional-rate-alt').text( label );
});

$(document).on('click', '.addReview', function(){
	var form = $(this).closest('form');
	var ident = $(this).data('ident');
	form.find('.review-label').removeClass('error');
	form.find('.errors-description').html();
	var errors = '';
	var valid = true;
	if (  $('input[name=rating]', form).val() == 0 ) {
		$('input[name=rating_' + ident + ']', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '�� ������ ������� ������.<br>';
		valid = false;
	}
	if (  $('input[name=recom_flag]:checked', form).length == 0 ) {
		$('input[name=recom_flag]', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '�� ������������ ���� �����?<br>';
		valid = false;
	}
	if ( $('.aditional-block', form).length > 0 ) {
		$('.aditional-rate', form).each(function( key, value ){
			if ( $(value).find('input[type=radio]:checked').length == 0 ) {
				$(value).closest('.row').find('.review-label').addClass('error');
				errors = errors + '�� ������o �������� ��������� "' + $(value).closest('.row').find('.review-label label').text() + '".<br>';
				valid = false;
			}
		});
	}
	if ( $('textarea[name=review]', form).val().length < 15 ) {
		$('textarea[name=review]', form).closest('.row').find('.review-label').addClass('error');
		errors = errors + '����� ������ ������ 15 ��������.<br>';
		valid = false;
	}
	if ( valid ) {
		form.submit();
	} else {
		form.find('.errors-description').html( errors );
	}
});
$(document).on('click', '.yandex_tbl .check_review', function(e) {
	e.preventDefault();
	$.ajax({
		type: "GET",
		url: "reviews_success.php?action=check_review",
		// dataType : "json",
		data: { 
			orders_id: $('#current_orders_id').val(),
			website: $(this).data('website'),
		},
		context: this,
		success: function(){
			$(this).html('��� ����� ����� �������� � ������� 3-5 ����.');
			$(this).addClass('just_send_review');
			$(this).removeClass('check_review');
		},
	});
});
$('form.make_review_form').submit( function(e){
	e.preventDefault();
	var type = $(this).data('type');
	var data = $(this).serializeArray();
	data.push( {'name': 'name', 'value': $(this).find('input[name=review_manual_name]').val() } );
	data.push( {'name': 'manualName', 'value': $(this).find('input[name=review_manual_name]').val() } );
	data.push( {'name': 'type', 'value': type } );
	data.push( {'name': 'rate', 'value': $(this).find('input[name=rating]').val() } );

	$(this).find('.error').empty();
	$.ajax({
		url: '/make_reviews.php?ajax=5&type=' + type,
		data: data,
		type: 'post',
		context: this,
		dataType: 'json',
		success: function( msg ){
			if ( msg['done'] == 1 )
			{
				var answer = '������� �� ��� �����!';
				$(this).html('<div class="thanks">' + answer + '</div>');
			}
		},
	});
});
$('.reviews-write-form').submit(function(e){
	e.preventDefault();
	var data = $(this).serializeArray();
	var postUrl = $(this).attr('action');
	$.ajax({
		url: postUrl + '&ajax=1',
		data: data,
		type: 'post',
		context: this,
		dataType: 'json',
		success: function( msg ){
			if ( msg['done'] == 'done' )
			{
				var answer = '������� �� ��� �����!',
						comment = '��� ����� �������� ����� �������� ������� ���������';
				$(this).html('<div class="thanks">' + answer + '</div><p>'+comment+'</p>');
			}
		},
	});
});

$('.more-reviews a').on('click', function(e){
	e.preventDefault();

	var ctrl = $(this);
	if( ctrl.data('loading') == 1 ) {
		return;
	}
	var cont = ctrl.closest('.section-expanded');
	var list = cont.find('.reviews-list');
	var revBlock = cont.closest('.reviews-block');
	ctrl.data('loading', 1);
	product.getMoreReviews( {
		products_id: $('input[name=products_id]').val(),
		limit:       ctrl.data('limit'),
		offset:      ctrl.data('from'),
		type:        ctrl.data('type'),
	}, function( msg ) {
		var el = list.append( msg.data );
		initRate();
		ctrl.data('from', ctrl.data('from') + ctrl.data('limit'));
		if( ctrl.data('from') >= msg.reviewsCount ) {
			ctrl.hide();
		}
		cont.find('.expand').slideDown({
			complete: function() {
				ctrl.data('loading', 0);
				list.find('>li').appendTo($('>.reviews-list', revBlock));
				cont.find('.more-reviews').hide();
			}
		});
	} );
});

function initReviewsPage() {
	var wrapper = $('.reviews-section');
	if (wrapper.length == 0) {
		return;
	}
	var form = $('#reviews-list-form', wrapper);
	var startParams = form.serializeArray();
	$('.js-more-reviews', wrapper).click(function(e) {
		e.preventDefault();
		if (parseInt(form.attr('loading')) == 1) {
			return false;
		}
		form.attr('loading', 1);
		var page_el = form.find('input[name=page]');
		page_el.val(parseInt(page_el.val()) + 1);
		formChangeFunc(form, true, true);
	});
	form.change(function(e) {
		if (parseInt(form.attr('loading')) == 1) {
			return false;
		}
		form.attr('loading', 1);
		form.find('input[name=page]').val(1);
		formChangeFunc(form, false, true);
	});

	var formChangeFunc = function(form, appendFlag, historyFlag) {
		var formData = form.serializeArray();
		if (historyFlag && history.pushState) {
			var queryString = $.param(formData);
			history.pushState(formData, $("title").text(), form.attr('action') + '?' + queryString.replace('&ajax=3', ''));
		}
		$.ajax({
			url: form.attr('action'),
			data: formData,
			dataType: 'json',
			success: function(msg) {
				if (appendFlag) {
					$('.js-reviews-list', wrapper).append(msg.data.content);
				} else {
					$('.js-reviews-list', wrapper).html(msg.data.content);
					// $(window).scrollTop(0);
				}
				form.attr('loading', 0);
				// $('.select-info .count-model', wrapper).text(msg.pagination.itemCountText);
				$('.js-reviews-navigation', wrapper).html(msg.pagination.html);
				if (parseInt(msg.pagination.pageNumber) >= parseInt(msg.pagination.pageCount)) {
					$('.js-more-reviews', wrapper).myHide();
				} else {
					$('.js-more-reviews', wrapper).myShow();
				}

			},
		});
	}
	$('.js-reviews-navigation', wrapper).on('click', '.j-page-link', function(e) {
		e.preventDefault();
		if (parseInt(form.attr('loading')) == 1) {
			return false;
		}
		form.attr('loading', 1);
		var page_el = form.find('input[name=page]');
		page_el.val($(this).data('page'));
		formChangeFunc(form, false, true);
	});

	$('.js-reviews-navigation', wrapper).on('change', '.j-select-page', function(e) {
		e.preventDefault();
		if (parseInt(form.attr('loading')) == 1) {
			return false;
		}
		form.attr('loading', 1);
		var page_el = form.find('input[name=page]');
		page_el.val($('option:selected', $(this)).data('page'));
		formChangeFunc(form, false, true);
	});

	addEvent('popstate', window, function(e) {
		if (e.state === null) {
			params = startParams;
		} else {
			params = e.state;
		}
		$('#reviews-list-form').deserialize($.param(params), true);
		$('.radio-list input:checked').closest('label').addClass('active').siblings().removeClass('active');
		formChangeFunc($('#reviews-list-form'), false, false);
	});
}

if ( window.PROSKTR.content == 'reviews' ) {
	initReviewsPage();
}