$(document).ready(function(){
	return;
	var data = {};
	data.products_id = $('.j-add-product-form > input[name=products_id]').val();
	var itemHolder = $('.product-information__price .prices');
	data.action = 'recalc_price';
	$.ajax({
		type: "GET",
		url: "/ajax_fetch_coupons.php",
		data: data,
		dataType : "json",
		success: function( msg ){
			var CurPrice = 0;
			if ( $('.new-price', itemHolder).length ) {
				var CurPrice = parseInt( $('.new-price', itemHolder).html().replace(/\D+/g,"") );
			} else {
				var CurPrice = parseInt( $(itemHolder).html().replace(/\D+/g,"") );
			}
					
			if( msg !== null ) {

				if( CurPrice != msg['price'] && msg['price'] != 0 ){
					// ������ ����

					var pct = Math.round( (msg['start_price'] - msg['price'])*20/msg['start_price'] ) * 5;
					// $(itemHolder).html('<span class="old-price">' + msg['start_price'] + ' ' + PROSKTR.currencies.right + '</span> <span class="new-price">' + msg['price'] + ' ' + PROSKTR.currencies.right + '</span> <span class="new-price-pct">(-' + pct + '% � ������ ������)</span>');
					$(itemHolder).html('<span class="old-price">' + msg['start_price'] + ' ' + PROSKTR.currencies.right + '</span> <span class="new-price">' + msg['price'] + ' ' + PROSKTR.currencies.right + '</span> <span class="new-price-pct">(-' + pct + '% � ������ ������)</span>');
				}
			}
		}
	});
});