var customer = window.customer || new Customer();
initNumberInput();
$('.default-validate-form').each(function() {
	$(this).validate({
		onChange : $(this).data('validateOnChange') != undefined
	});
});
$(document).on('click', '.j-s-sbmt', function(e) {
	e.preventDefault();
	$(this).closest('form').submit();
	if ( $(this).hasClass('toOrder') && !$(this).closest('form').hasClass('form-valid-error') ) {
		$('#accountInfo').hide();
		$('#order-tab').show();
		$('#delivery').empty();
		$('#customerAddress').empty();
	}
});
initSCSuggestion();
initSCSuggestionLegal();
toggleDontCall();

$(document).on('change', '.delivery-fitting', function(){
	var fittingCost = parseInt($(this).val());
	var deliveryCost = parseInt($('.side-delivery-cost').data('value'));
	var totalCost = parseInt($('.side-total-cost').data('value'));
	$('.side-delivery-cost').html( fittingCost + deliveryCost + ' ' + window.PROSKTR.currencies.right );
	$('.side-total-cost').html( fittingCost + totalCost + ' ' + window.PROSKTR.currencies.right );
});

$(document).on('click', '.cart-change-size', function(e) {
	e.preventDefault();
	if ( $(this).hasClass('disabled') ) {
		return false;
	}

	var editSizeProductLayerEl = $('#change-item-modal .modal-frame');
	var tdInfo = $(this).closest('.cart-list__row');
	var sendData = {
		'id': {},
		'products_id': {}
	};
	sendData['id'][ tdInfo.data('id') ] = {};
	sendData['id'][ tdInfo.data('id') ][ 1 ] = tdInfo.data('optionId');
	sendData['products_id'][0] = tdInfo.data('id');
	$.ajax({
		url: '/ajax_shopping_cart.php?action=get_info',
		type: 'POST',
		dataType: 'json',
		data: sendData,
		success: function( msg ) {
			$('.cart-change-size-opener').trigger('click');
			editSizeProductLayerEl.html( $.render[ 'edit-size-product-layer' ].render( msg ) );
			$(editSizeProductLayerEl).on('submit', 'form', function(e){
				e.preventDefault();
				$.ajax({
					url: $(this).attr('action'),
					type: 'post',
					dataType: 'json',
					data: $(this).serializeArray(),
					success: function( answer ) {
						var productsHtml = $.render[ 'show_product_tpl' ].render( answer.products, { currencies: window.PROSKTR.currencies } );
						var productsSideHtml = $.render[ 'edit_product_tpl' ].render( answer.products, { currencies: window.PROSKTR.currencies } );
						$('.cart-list-products').html('');
						$('.orders-list').html('');
						$('.cart-list-products').append(productsHtml);
						$('.orders-list').append(productsSideHtml);
						$('#change-item-modal .close-modal').trigger('click');
					}
				});
			});
		}
	});
});

function initNumberInput() {
	$(document).on('change', '.j-cart-select-qty', function(e){
		e.preventDefault();
		var data = $(this).closest('form').serializeArray();
		data.push({name: 'fromPage', value: window.PROSKTR.content});
		$.ajax({
			url: 'ajax_shopping_cart.php?action=update_product_new',
			type: 'POST',
			dataType: 'json',
			context: this,
			data: data,
			success: function(responseText)  { // ����� ��������� ������
				changeQuantitySuccess( this, responseText );
			},
		});
	});

	$(document).on('change', '.cart-list-products .count-num input', function() {
		var currentQty = parseInt( $(this).val(), 10 );
		var qty = fixQty( $(this).val(), 1, $(this).data('max'), 0 );
		if( currentQty != qty ) {
			$(this).val( qty );
		}

		$(this).closest('form').find("input.currCount").val( qty );
		var data = $(this).closest('form').serializeArray();
		data.push({name: 'fromPage', value: window.PROSKTR.content});
		$.ajax({
			url: 'ajax_shopping_cart.php?action=update_product_new',
			type: 'POST',
			dataType: 'json',
			context: this,
			data: data,
			success: function(responseText)  { // после получения ответа
				changeQuantitySuccess( this, responseText );
			},
		});
	});

	$(document).on('submit', '.cart-list-products .count-form', function(e){
		e.preventDefault();
		var $input = $('.count-num input', this);
		var currentQty = parseInt( $input.val(), 10 );
		var qty = fixQty( $input.val(), 1, $input.data('max'), 0 );
		if( currentQty != qty ) {
			$('.count-num input', this).val( qty );
		}

		$(this).find("input.currCount").val( qty );
		var data = $(this).serializeArray();
		data.push({name: 'fromPage', value: window.PROSKTR.content});
		$.ajax({
			url: 'ajax_shopping_cart.php?action=update_product_new',
			type: 'POST',
			dataType: 'json',
			context: this,
			data: data,
			success: function(responseText) {
				changeQuantitySuccess( this, responseText );
			},
		});
	});

	$(document).on('click', '.cart-list__row .del', function(e){
		e.preventDefault();
		data = {};
		data.products_id = $(this).closest('.cart-list__row').find('[name="products_id[]"]').val();
		shoppingCart.deleteProduct( data );
	});
}

function changeQuantitySuccess( _this, responseText ) {
	$('.header-cart .cart-content-count').html(responseText.count_contents);
	$('.basket-aside .price').html(responseText.total + ' ' + window.PROSKTR.currencies.right);
	$('.cart-total-discount').html((responseText.real_total - responseText.total) + ' ' + window.PROSKTR.currencies.right);
	$('.cart-total').html(responseText.total + ' ' + window.PROSKTR.currencies.right);

	$('.side-products-cost').data('value', responseText.total).html(responseText.total + ' ' + window.PROSKTR.currencies.right);
	$('.side-discount').data('value', responseText.real_total - responseText.total).html((responseText.real_total - responseText.total) + ' ' + window.PROSKTR.currencies.right);
	$('.side-total-cost').data('value', parseInt(responseText.total)).html(parseInt(responseText.total) + ' ' + window.PROSKTR.currencies.right);

	var productsArr = responseText['products'];
	recalculateCartList( productsArr );
	if ( $('#form-get-delivery').length ) {
		$('#form-get-delivery').submit();
	}
	shoppingCart.setQuantityNPrice(responseText);
}

function recalculateCartList( _products ) {
	var productsList = [];
	$.each( _products, function( index, val ) {
		val.id = val.products_id;
		val.bimage_link = val.image_path;
		val.value_formatted = val.final_price+' '+window.PROSKTR.currencies.right;
		productsList.push( val );
	});

	var cart = $('.basket-aside');
	$('.drop-cart-list', cart).html($.render[ 'shopping_cart_popup_product' ].render(productsList));
}

$.fn.delivery_date_for_time_change = function() {
	this.each(function() {
		var delivery_row = $(this).closest('.cureer-block');
		var time_select = delivery_row.find('[name='+$(this).attr('time')+']');
		time_select.empty();
		var arr = window[$(this).attr('time')];
		for (var i = 0; i < arr.length; i++) {
			if( arr[i][0]==$(this).val() ) {
				var o = new Option( arr[i][1], arr[i][1])
				$(o).html( arr[i][1] );
				time_select.append(o);
			}
		}
		// time_select.styler('destroy').styler();
	});
}

if( $('.fitting-descrption-popup-opened').length ) {
	$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('show');
	});
	$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('hide');
	});
	$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
		e.preventDefault();
		$('#sc-primerka-popup').modal('hide');
		$('html, body').animate({ scrollTop: $('#products-tab').offset().top });
	});
}

customer.setCallback('onLogin', function() {
	$('#form-get-customer-city').submit();
	$('#accountInfo').hide();
	$('#order-tab').show();
});

var ajaxSCFormOptions = {
	dataType: 'json',
	error: function(context, xhr, e, status) {
	},
	uploadProgress: function(event, position, total, percent) {
	},
	beforeSubmit: function(formData, jqForm, options) {
		if( parse_str(options.url).action.indexOf('getCustomerCity') >= 0 ) {
			$('#form-get-login').hide();
		}
		// �������� ��������, ���� �����
		if( parse_str(options.url).action.indexOf('getShipping') >= 0 ) {
			if( formData.length ) {
				if ( window.ga ) {
					ga('ec:setAction','checkout', {
						'step': 3,
						'option': formData[0].value
					});
					ga('send', 'pageview');
				}
				if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
				{
					window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_city');
				}
			}
			$('#accountInfo #acc-change-btn').hide();
			$('#customerAddress, #delivery').empty();
			$('#delivery').addClass('loading');
		}

		if( parse_str(options.url).action.indexOf('getAddressFields')>=0 ) {
			$('#customerAddress').empty();
			$('#customerAddress').addClass('loading');
		}
		if( $('#'+jqForm.attr('id')+'-loading').length ) {
			$('#'+jqForm.attr('id')+'-loading').css('visibility', 'visible');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'visible');
		}
		if( $(jqForm).attr('id') == 'form-get-login' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).next().attr('id') == 'form-get-content' ) {
				$(jqForm).hide().next().show();
			}
			$.each( $('#form-get-delivery').serializeArray(), function(i, val) {formData.push(val); } );
		}
		if( $(jqForm).attr('id') == 'form-get-content' ) { // ���� ��� ����� �����������, �� ������� ��� � ��������� �����, ����� ����� ����������� ������� ������ �������� �����
			if( $(jqForm).prev().attr('id') == 'form-get-login' ) {
				$(jqForm).hide().prev().show();
			}
		}
		formData.push({name: 'dataType', value: options.dataType});
	},
	success: function(responseText, statusText, xhr, $form) {				
		// �������� ��� ����
		if( $form.attr('id') == 'createAccountForm' ) {
			$('[name=email_address]', $form).prop('readonly', true);
			$('[name=password]', $form).closest('.form-row').hide();
			if ( window.ga ) {
				ga('ec:setAction','checkout', {
					'step': 2,
					'option': ''
				});
				ga('send', 'pageview');
			}
			if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
			{
				window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_nameemailtel_filled');
			}
		}
		
		if( $('#'+$form.attr('id')+'-loading').length ) {
			$('#'+$form.attr('id')+'-loading').css('visibility', 'hidden');
		} else {
			$('.order .ajax-loading.default').css('visibility', 'hidden');
		}
		if ( responseText.shipping_cost != undefined ) {
			$('.side-delivery-cost').data('value', responseText.shipping_cost).html( responseText.shipping_cost + ' ' + window.PROSKTR.currencies.right );
			$('.side-total-cost').html(parseInt(responseText.total_formatted) + ' ' + window.PROSKTR.currencies.right);
		}

		$.each( responseText.data, function( id, data ) {
			var place = $('#' + id);
			if( place.length ) {
				place.html( data );
				place.removeClass('loading');
				// hidePaymentButtons(); // TODO
				if( place.find('.default-validate-form').length ) {
					place.find('.default-validate-form').validate();
				}
				if( place.find('.ajaxSCForm').length ) {
					place.find('.ajaxSCForm').ajaxForm(ajaxSCFormOptions);
				}
				place.find('.tooltip').tooltip_init();
				// if( place.find(':checkbox').length ) {
				// 	place.find(':checkbox').checkbox();
				// } // TODO
				place.find('.phone-11-dig').phoneMask();
				if( $.inArray('setInputFocus', responseText.options)!=-1 ) {
					place.find('input:text:first').focus();
				}
				if( $('select.delivery-fitting', place).length ) {
					$('select.delivery-fitting', place).trigger('change.fitting');
				}
				$('.with-placeholder input', place).each(function() {
					$(this)[ $(this).val().trim().length == 0 ? 'removeClass' : 'addClass' ]('edited');
				});
				if( $('.fitting-descrption-popup-opened').length ) {
					$(document).on('click', '.fitting-descrption-popup-opened', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('show');
					});
					$('#sc-primerka-popup').on('click', '.btn-no-change', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('hide');
					});
					$('#sc-primerka-popup').on('click', '.bnt-change', function(e) {
						e.preventDefault();
						$('#sc-primerka-popup').modal('hide');
						$('html, body').animate({ scrollTop: $('#products-tab').offset().top });
					});
				}
			}
			// BOF _gaq.push
			if( id=='customerAddress' ) {
				toggleDontCall();
				$("#customerAddressForm").submit(function(e) {
					if ( $(this).hasClass('form-valid-error') ) {
						return false;
					}
					$('input:submit', this).prop('disabled', 'disabled');
					$('input:submit', this).closest('label').addClass('loading-in-btn');
					$('.order-success-btn', this).removeClass('hidden');
				});
			}
			// EOF _gaq.push
		} );
		if( responseText.customerInfo != undefined ) {
			customer.logined = true;
			customer.info.email = responseText.customerInfo.email;
			customer.info.customer_id = parseInt(responseText.customerInfo.customers_id);
		}
		if( $.inArray('cityFocus', responseText.options)!=-1 ) {
			$('#customerCity .city-tab.active input[name=city]').focus();
		}
		if( $.inArray('delivery_date_for_time_change', responseText.options)!=-1 ) {
			$('select.delivery_date_for_time').delivery_date_for_time_change();
		}
		if( responseText.scrollTo && responseText.scrollTo != '#delivery' && responseText.scrollTo != '#customerCity' && responseText.scrollTo != '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $(responseText.scrollTo).offset().top });
		} else if ( responseText.scrollTo && responseText.scrollTo == '#customerAddress' ) {
			$('html, body').animate({ scrollTop: $('#delivery .info-block__head').offset().top });
		}
		var stepsList = $('.order-steps');
		init_sc_autocomplete();
		initSCSuggestion();
		initSCSuggestionLegal();
	}
}
$('.ajaxSCForm').each(function() {
	$(this).ajaxForm(ajaxSCFormOptions);
});

$('.cart-list__presents').on('change', 'select[name=customers_bonus]', function() {
	var div = $(this).closest('.customers-presents');
	div.find('.ajax-loader').show();
	$.ajax({
		type: 'POST',
		data: {'choosen_bonus': $(this).val()},
		url: 'ajax_shopping_cart.php?action=set_bonus',
		dataType: 'json',
		context: this,
		success: function(msg) {
			$.ajax({
				url: 'ajax_shopping_cart.php?action=get_info',
				type: 'POST',
				dataType: 'json',
				context: this,
				success: function(responseText)  { // ����� ��������� ������
					changeQuantitySuccess( this, responseText );
				},
			});
		}
	});
});

$('#customerAddress, form#createAccountForm').on('change', 'input,select,textarea', function(e) {
	var dat = { 'dataType': 'json', 'forSave': {}};
	dat.form = $(this).closest('form').attr('id');
	dat['forSave'][$(this).attr('name')] = this.value;
	$.ajax({
		url: 'shopping_cart.php?action=setCustomerInfo&ajax=3',
		data: dat,
		dataType: 'json',
		type: 'POST',
		success: function(msg) {}
	});
});

$(document).on('click', '.pass-forgotten', function(e) {
	el = this;
	form = $(el).closest('form');
	customer.sendNewPass({
			email_address: form.find('input[name=email_address],input[name=email_or_phone],input[name=phone]').val()
		},
		function( msg ) {
			$(el).closest('.row').find('.hint').html(msg);
		}
	);
	e.preventDefault();
});

$('#delivery').on('change', 'select.delivery_date_for_time', function( e ) {
	$(this).delivery_date_for_time_change();
});
$('select.delivery_date_for_time').delivery_date_for_time_change();

$('#delivery').on('change', 'input[name=shipping]', function( e ) {
	var cureer_block = $(this).closest('.cureer-block');
	$('.cureer-block').removeClass('active').css('border-bottom', '');
	cureer_block.addClass('active');
	$('.delivery_selects').hide();
	$('.delivery_selects.select_' + $(this).val() ).show();
	cureer_block.addClass('active').siblings().addClass('d-none');
	$('.change-delivery').removeClass('d-none');
	if( this.value=='pvz_pvz' ) {
		if( cureer_block.find('input[name=pvz_id]').val().length==0 ) {
			$('#delivery .shops_map_call').trigger('click');
			this.checked = false;
			return false;
		}
	}
	$('.change-delivery').removeClass('d-none');
	$('.more-delivery').addClass('d-none');
	$(this).closest('form').attr('action', 'shopping_cart.php?ajax=1&action=setShipping.getAddressFields.getPayments') // ������ �������, ������ ����� �� ��������:(
	$(this).closest('form').submit();
	$(this).closest('div.row').siblings(':visible').fadeOut({duration: 200});
	if ( window.ga ) {
		ga('ec:setAction','checkout', {
			'step': 4,
			'option': e.target.defaultValue
		});
		ga('send', 'pageview');
	}
	if ( window['yaCounter' + window.PROSKTR.yaCounterId] )
	{
		window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal('shopping_cart_choose_delivery');
	}
});

$('#delivery').on( 'click', '.shops_map_call', function(e) {
	e.preventDefault();
	var options = $(this).data('map-options');
	$('#see-map').trigger('click');
	var el = $(this);
	ymaps.ready(function() {
		$('#map-popup').shopsMap($.extend(options, {
			pvzChoose: function( properties ) {
				$('#pvz_id').val( properties.point_ident );
				$('#pvz_resource').val( properties.delivery );
				$('#pvz__city').val( properties.point_city );
				$('#pvz_iml_date').val( properties.pvz_iml_date );
				// el.text('�������� ����� ������ �� �����');
				var cureer_block = el.closest('.cureer-block');
				$('.send-value', cureer_block).text( properties.dates.send_format ); // ���� ��������
				$('.delivery-value', cureer_block).text( properties.dates.delivery_from_format ); // ���� ��������
				$('.col-2 .head', cureer_block).text( properties.dates.delivery_days_forprint ); // ��� ��������

				$('.col-3 .head', cureer_block).text( properties.cost == 0 ? '���������' : properties.cost + '�' ).data('value', properties.cost); // ����
				$('.col-3 .head-on-line', cureer_block).remove();
				if( properties.free_cost == 1 && properties.cost > 0 ) {
					$('.col-3', cureer_block).append( '<strong class="head-on-line tooltip" title="<b>���� ������ �������� ����� ���� ���������� ��� ������ ������!</b><br />��������� �������� ���������� ����� ������ ������.">��������� <div class="icon"><div class="point-pay_cache_no"></div></div></strong>' ); // ����
					$('.col-3 .head-on-line', cureer_block).tooltip_init();
				}

				if( $.inArray(properties.delivery, options.typesToFitting) != -1 ) {
					$('.fitting-holder', cureer_block).show();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				} else {
					$('.fitting-holder', cureer_block).hide();
					// if( $('select.delivery-fitting', cureer_block).length ) $('select.delivery-fitting', cureer_block).styler();
				}

				$('.error', cureer_block).text('');
				$('input[name=shipping]', cureer_block).prop('checked', true);
				$('select.delivery-fitting', cureer_block).val(properties.fitting).trigger('change');
				$('input[name=shipping]', cureer_block).trigger('change');
				$('#pvz_text', cureer_block).html('<b>����� ����� ��������� �� ������:</b><br>' + properties.point_city + ', ' + properties.address + ' (���� ' + properties.delivery_name + ')');
				$('#map-popup .close-modal').trigger('click');
			}
		}));
	});
} );

$('#delivery').change(function(e) {
	if( e.target.name=='shipping' || $(this).find('input[name=shipping]:checked').length==0 ) {
	} else {
		$(this).attr('action', 'shopping_cart.php?ajax=1&action=setShipping');
		$(this).closest('form').submit();
	}
});

$(document).on('click', '.ordering-list li', function(e){
	e.preventDefault();
	if ( $(e.target).hasClass( 'shops_map_call' ) ) {
		return false;
	}
	$(this).closest('.payment-block').find('.ordering-list li').removeClass('active');
	$(this).closest('.payment-block').find('.ordering-list li input.payment-type').prop('checked', false);
	$(this).closest('.ordering-list').siblings('input[name=paymentGroup]').prop('checked', true).trigger('change');
	if ( $(this).find('input[name=shipping]').val() != 'pvz_pvz' || !$('input[name=pvz_id]').val().length==0 ) {
		$(this).find('input[type=radio]').prop('checked', true).trigger('change');
	} else {
		$('#delivery .shops_map_call').trigger('click');
	}
	if ( $(this).closest('#payments').length ) {
		$(this).closest('#payments').find('.ordering-list li').not(this).addClass('d-none');
		$(this).addClass('active').css('border-bottom', '');
		$('.change-payment').removeClass('d-none');
		$('.more-payments').addClass('d-none');
		if ( $(this).closest('.other').length ) {
			$('.more-payments').addClass('no-hide');
		} else {
			$('.more-payments').removeClass('no-hide');
		}
		toggleDontCall();
	}
});

$(document).on('click', '.change-delivery', function(e){
	e.preventDefault();
	$('#delivery').find('.ordering-list li').removeClass('d-none');
	$('#delivery').find('.ordering-list li:not(:last-of-type)').css('border-bottom', '1px solid #ccc');
	$('.change-delivery').addClass('d-none');
});

$(document).on('click', '.change-payment', function(e){
	e.preventDefault();
	$('#payments').find('.ordering-list li').removeClass('d-none');
	$('#payments').find('.ordering-list li:not(:last-of-type)').css('border-bottom', '1px solid #ccc');
	$('.more-payments').removeClass('d-none');
	$('.change-payment').addClass('d-none');
});

$(document).on('click', '.more-payments', function(e){
	e.preventDefault();
	if( $(this).hasClass('no-hide') ) {
		return;
	}
	if( $(this).hasClass('disabled') ) {
		$('#payments .other').slideUp();
		$(this).removeClass('disabled');
		$(this).text( $(this).data('text-hide-more') );
	} else {
		$('#payments .other').slideDown();
		$(this).addClass('disabled');		
		$(this).text( $(this).data('text-show-more') );
	}
});

$(document).on('click', '.sort-feature .custom-check', function() {
	if ( $(this).find('input[type=checkbox]').prop('checked') ) {
		$(this).closest('.sort-feature').addClass('active');
	} else {
		$(this).closest('.sort-feature').removeClass('active');
	}
});

$(document).on('submit', '#customerAddressForm', function(){
	let $form = $(this);
	if ( $(this).hasClass('form-valid-error') ) {
		if ( $('input[name=paymentGroup]:checked').val() === undefined ) {
			$('.payment-validation-description').text('�� ������ ������ ������');
		} else {
			$('.payment-validation-description').text('');
		}
		$('.form-valid-error-desctiption').show();
		$('html, body').animate({ scrollTop: $('#customerAddressForm').offset().top });
	} else {
		$('.form-valid-error-desctiption').hide();
		$('.j-createorder', $form).hide();
		$('.j-creatingorder', $form).show();
	}
});

function initSCSuggestion() {
	var 
		token = "f0018b902c3b14ca14ed104fc8fd52b1550234e7 ",
		type  = "ADDRESS",
		$street = $("#street-dadata");
		$house = $("#house-dadata");
		if ( !$street.length || !$house.length ) {
			return false;
		}
	if( $('#city-dadata').length == 0 ) {
		return;
	}

	$street.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "street",
		constraints: {
			locations: {
				city: $('#city-dadata').val(),
			},
		},
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

	$house.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		bounds: "house",
		constraints: $street,
		onSelect: function(suggestion) {
			onSelectSuggestion(suggestion);
		},
	});

	setTimeout( function(){
		if ( $("#house-dadata").length && $("#street-dadata").val() != '' ) {
			$("#house-dadata").suggestions().fixData();
		}
	}, 1000);
}

function initSCSuggestionLegal() {
	var
		token = "f0018b902c3b14ca14ed104fc8fd52b1550234e7 ",
		type  = "PARTY",
		$organisation = $("#organisation-dadata");

	if( $('#organisation-dadata').length == 0 ) {
		return;
	}

	$organisation.suggestions({
		serviceUrl: "https://suggestions.dadata.ru/suggestions/api/4_1/rs",
		token: token,
		type: type,
		hint: false,
		onSelect: function(suggestion) {
			var wrapper = $('.j-legal-form');
				$('input[name="info_legal_psrn"]', wrapper).val( suggestion.data.ogrn ); // ����
				$('input[name="info_legal_itn"]', wrapper).val( suggestion.data.inn ); // ���
				$('input[name="info_legal_iec"]', wrapper).val( suggestion.data.kpp ); // ���
				$('input[name="info_legal_address"]', wrapper).val( suggestion.data.address.unrestricted_value ); // �����
				$('input[name="organisation"]', wrapper).val( suggestion.data.name.short_with_opf ); // ������� ��������
		},
	});
};

function onSelectSuggestion(suggestion) {
	if ( suggestion.data.postal_code != null && $('#customerAddress input[name=index]').length )
	{
		$('#customerAddress input[name=index]').val( suggestion.data.postal_code ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=index]').val('');
	}
	if ( suggestion.data.house != null && $('#customerAddress input[name=house]').length )
	{
		$('#customerAddress input[name=house]').val( suggestion.data.house ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=house]').val('');
	}
	if ( suggestion.data.block != null && $('#customerAddress input[name=housing]').length ) {
		$('#customerAddress input[name=housing]').val( suggestion.data.block ).trigger('change');
	}
	else
	{
		$('#customerAddress input[name=housing]').val('').trigger('change');
	}
}
$(window).scroll(function(){
	if ( $('.cart-block__tools:not(.fixed)').length ) {
		var currentScroll = $(window).scrollTop();
		var staticCostPos = $('.cart-block__tools:not(.fixed)').position().top;
		if ( $('#accountInfo').css('display') == 'none' ) {
			staticCostPos = $('#ordering').position().top;
		}
		if ( currentScroll - staticCostPos + $(window).height() - $('.cart-block__tools.fixed').height() < 0 )
		{
			$('.cart-block__tools.fixed').css('display','block');
		}
		else
		{
			$('.cart-block__tools.fixed').css('display','');
		}
	}
});
$(window).trigger('scroll');
$('form#createAccountForm').on('change', '[name=1click_order_submit]', function(){
	var btn = $(this).closest('form').find('.sc-submit');
	var altValue = btn.data('altValue');
	btn.data('altValue', btn.text());
	btn.text(altValue);
	if( this.checked ) {
		$('form#createAccountForm').ajaxFormUnbind();
	} else {
		$('form#createAccountForm').ajaxForm(ajaxSCFormOptions);
	}
});
$(document).on('click', '.j-show-order', function(e) {
	e.preventDefault();
	$('html, body').animate({ scrollTop: $('#ordering').offset().top - $('.main-header').height() });
});
function toggleDontCall() {
	var checkedPaymentEl = $('#payments .payment-block .radio-list input:radio:checked');
	var checkedPayment = $(checkedPaymentEl).val();
	var currentVal = $('.dontCallLabel').data('current');
	if ( checkedPaymentEl.length && checkedPayment != 'cod' ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else if ( $('input[name=dontCall]').data('showdontcall') == 1 ) {
		$('.dontCallLabel').css('display', 'block');
		if ( currentVal == 'off' ) {
			$('input[name=dontCall][value=off]').prop('checked', true);
		} else {
			$('input[name=dontCall][value=on]').prop('checked', true);
		}
	} else {
		$('.dontCallLabel').css('display', 'none');
		$('input[name=dontCall][value=off]').prop('checked', true);
	}
}
$('#delivery').on('click', '.del-all-product-label', function( e ) {
	e.preventDefault();
	var data = { products_id: [], cart_delete: [] };
	var sendData = { products_id: [], cart_delete: [] };
	$('.del-product-label').each(function(){
		sendData.products_id.push( $(this).attr('products_id') );
		sendData.cart_delete.push( $(this).attr('products_id') );
		data.products_id.push( $(this).attr('products_id') );
		data.cart_delete.push( $(this).attr('products_id') );
	});
	data.success = function() {
		$('#form-get-delivery').submit();
	}
	var deleteProductCallback = typeof shoppingCart.options.deleteProductCallback == 'function' ? shoppingCart.options.deleteProductCallback( data ) : shoppingCart.deleteProductCallback( data ) ;
	$.ajax({
		url: '/ajax_shopping_cart.php?action=update_product&ajax=1',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		success: deleteProductCallback
	});
});

$('.j-show-coupon-from').on('click', function(){
	$('.j-coupon-form-holder').slideToggle(300);
});
$('.j-show-certificate-from').on('click', function(){
	$('.j-certificate-form-holder').slideToggle(300);
});

$('#createAccountForm input[name=passCode]').on('keyup', function(){
	var val = $(this).val();
	if ( val.length == 4 ) {
		customer.loginByPassCode({
			customerInfo: {
				phone: $('#createAccountForm input[name=phone]').val(),
				passCode: $('#createAccountForm input[name=passCode]').val(),
				clearCart: 1,
			},
			error: function(msg) {
				$('#createAccountForm input[name=phone]').data('validPhoneStatus', 0);
				$('#createAccountForm input[name=passCode]')
					.closest('.pass-row')
					.find('.validation-description')
					.html('<div class="error">' + msg.err_mes + '</div>')
					.addClass('not-empty');
			},
			success: function(msg) {
				$('#createAccountForm input[name=phone]').data('validPhoneStatus', 3);
				if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
					window.location.reload();
				}
			}
		});
	}
});

$('#createAccountForm input[name=passCode]').on('change', function(){
	var val = $(this).val();
	if ( val.length > 0 && val.length != 4 ) {
		$('#createAccountForm input[name=passCode]')
			.closest('.pass-row')
			.find('.validation-description')
			.html('<div class="error">� ���������� ���� ������ ���� 4 �����</div>')
			.addClass('not-empty');
	}
});

$('#createAccountForm .sendNewPassCode').on('click', function(e){
	e.preventDefault();
	var $form = $('#createAccountForm');
	if ( $('input[name=phone]', $form).val() != '' ) {
		$.ajax({
			url: '/ajax_customer.php?action=resendNamePassCode&ajax=1',
			type: 'POST',
			data: $form.serializeArray(),
			dataType: 'json',
			context: this,
			success: function( msg ) {
				$('.hint', $form).html(msg.err_mes);
			}
		});
	}
});

$(document).on('change', 'input[name=order_type]', function() {
	$('#payments').find('.ordering-list li').removeClass('d-none');
	$('#payments').find('.ordering-list li:not(:last-of-type)').css('border-bottom', '1px solid #ccc');
	$('.more-payments').removeClass('d-none');
	$('.change-payment').addClass('d-none');
	if( $(this).prop('checked') ) {
		$('.j-legal-form').myShow();
		$('.j-common-payments').myHide();
		$('.j-legal-payments').myShow();
		$('.j-legal-form .j-set-required').data('required', true);
		$('.j-common-payments .j-set-required').removeData('required');
		$('.j-legal-payments .j-set-required').data('required', true);
		$('.j-common-payments input:radio').prop('checked', false);
	} else {
		$('.j-legal-form').myHide();
		$('.j-common-payments').myShow();
		$('.j-legal-payments').myHide();
		$('.j-legal-form .j-set-required').removeData('required');
		$('.j-common-payments .j-set-required').data('required', true);
		$('.j-legal-payments .j-set-required').removeData('required');
		$('.j-legal-payments input:radio').prop('checked', false);
	}
});

function checkoutShowCertForm() {
	$("#checkoutAddCertBtn").toggleClass('disabled');
	$("#certificateFormHolder").toggle('fast');
}

$(document).on('click', '#�ertificateFormBtn', function(e){
	e.preventDefault();
	let $form = $(this).closest('#�ertificateForm');
	if ( $('input[name=certificateCode]', $form).val().length == 12 ) {
		let data = { 'certificateCode': $('input[name=certificateCode]', $form).val() };
		$.ajax({
			url: 'ajax_shopping_cart.php?action=activateCertificate',
			type: 'POST',
			dataType: 'json',
			data: data,
			success: function(responseText)  { // ����� ��������� ������
				if ( responseText.success == true ) {
					$('#getShippingForm').trigger('submit');
				} else {
					if ( responseText.result == 'already' ) {
						$('#�ertificateFormResult').text('���������� ��� ��� �����������.');
					} else {
						$('#�ertificateFormResult').text('��� ��������� ����������� ��������� ������.');
					}
				}
			},
		});
	} else {
		$('#�ertificateFormResult').text('������ �������� ��� �����������.');
	}
});

$(function () {
	if ( $('#createAccountForm:visible').length > 0 ) {
		if ( $('#createAccountForm:visible input[name=phone]').val().length > 0 ) {
			$('#createAccountForm:visible').submit();
		}
	}
})