// http://javascript-minifier.com/
(function(){
	if( $("form.quick_find").length ) {
		var cache = {};
		function getFromCache( cache, search){
		if ( search in cache ) {
			return cache[ search ];
		}
		search = search.split(' ');
		var data = [];
		for (var j = search[0].length - 1; j >= 0; j--) {
			term_tmp = search[0].substr(0, j);
			if( term_tmp in cache ){
				for (var i = 0; i <= cache[ term_tmp ].length - 1; i++) {
					if(cache[ term_tmp ][i].value.toLowerCase().indexOf(search[0])!==-1) data.push(cache[ term_tmp ][i]);
				}
				break;
			}
		};
		for (var i = search.length - 1; i > 0; i--) {
			for (var k = data.length - 1; k >= 0; k--) {
				if((strpos = data[k].value.toLowerCase().indexOf(search[i]))===-1){
					data.splice(k, 1);
				}else{
					if(strpos!=0 && data[k].value.toLowerCase().indexOf(' '+search[i])===-1){
						data.splice(k, 1);
					}
				}
			}
			}
			if(data.length){
				return data;
			}else{
				return false;
			}
		}

		$('form.quick_find').each(function(){
			$('input[name=keywords]', this).autocomplete({
				minLength: 1,
				// appendTo: "#search_result",
				source: function( request, response ) {

					var term = request.term.toLowerCase();
					data = getFromCache(cache, term);
					if(data!==false){
						if ( !(term in cache) ){
							cache[ term ] = data;
						}
						response( data );
						return;
					}
					$.getJSON( "/search.php?type=ajax_search", {'term': term.substr(0, 1)}, function( data, status, xhr ) {
						cache[ term.substr(0, 1) ] = data;
						data = getFromCache(cache, term);
						if(data!==false){
							if ( !(term in cache) ){
								cache[ term ] = data;
							}
							response( data );
							return;
						}
					});
				},
				select: function( event, ui ) {
					if(ui.item!=undefined){
						// this.value = $(event.srcElement).closest('.ui-menu-item').find('span.result').text();
						this.value = ui.item.value;
					}else{
					}
					this.closest("form.quick_find").submit();
					return false;
				},
				change: function( event, ui ){
					if(ui.item==null){
						var ul = $( "#autocomplete" ).autocomplete( "widget" ).show();
						if(ul.find('li.ui-menu-error').length==0) {
							ul.prepend("<li class='ui-menu-error'><span>�������� �� ������</span></li>");
						}
					}
				},
				menufocus: function( event, ui ) {},
			}).data( "ui-autocomplete" )._renderItem = function( ul, item ) {
				return $( "<li class='ui-menu-item '>" )
					.append( "<a class='suggestion ui-corner-all'><span class='result'>"+item.value+"</span><span class='result-count'>"+item.count+"</span></a>" )
					.appendTo( ul );
			};
			$('input[name=keywords]', this).keyup(function(){
				if($('#clear-search>a').is(':hidden') && !$('form[name=quick_find] .div-loading').is(':visible') && this.value.length>0){
					$('#clear-search>a').fadeIn();
				}else{
					if($('#clear-search>a').is(':visible') && this.value.length==0){
						$('#clear-search>a').fadeOut();
					}
				}
			});
			$(this).submit(function(){
				$('#clear-search>a').hide();
				$('form.quick_find .div-loading').show();
			});
			$('input[name=keywords]', this).focus(function(){
				if(this.value.length==0 ){
					var _this = this;
					cookie_recent_search = cookieObj.getCookie('recentsearch');
					if(cookie_recent_search!=undefined && cookie_recent_search!='undefined' && cookie_recent_search.length){
						var ul = $(_this).autocomplete( "widget" );
						ul.empty();
						cookie_recent_search = cookie_recent_search.split(',');
						$( "<li class='ui-menu-title '>" ) .append( "�� ��� ������:" ) .appendTo( ul )
						for (var i = cookie_recent_search.length - 1; i >= 0; i--) {
							$( "<li class='ui-menu-item '>" ) .append( "<a class='suggestion ui-corner-all'><span class='result'>"+cookie_recent_search[i]+"</span><span class='result-count'></span></a>" ) .appendTo( ul )
						}
						$(ul).find('li.ui-menu-item').click(function(){
							$(_this).val( $(_this).text() );
						});
						ul.show();
						ul.position( $.extend({of: _this }, $(_this).autocomplete( "option", 'position' ) ));
						ul.outerWidth( Math.max(ul.width( "" ).outerWidth() + 1, $(_this).outerWidth() ) );
					}
				}
			});
			$('#clear-search>a').click(function(){
				$('input[name=keywords]', this).val('');
				$('input[name=keywords]', this).focus();
				$(this).fadeOut();
				return false;
			});
		});
	}
})();