// http://javascript-minifier.com/
(function () {
	
	$( document ).ready(function() {
		$('.subscribe input[name=email]').val('');
		$('.subscribe input[name=name]').val('');
		$('.subscribe input[name=phone]').val('');
	});

	// var customer = new Customer();
	// email statuses:
	// 	0: not_checked
	// 	1: process
	// 	2: email not exists 
	// 	3: email exists not logined
	// 	4: email exists logined
	// 	5: email exists not subscribe no name
	// 	6: email exists not subscribe have name
	// 	7: email exists and subscribe 

	$(".submit_sucsribe_m").click(function( e ){
		subscribe( e, 'm');
		return false;

	});
	$('.submit_sucsribe_f').click(function( e ){
		subscribe(e, 'f');
		return false;
	});

	// $('.subscribe').submit(function(e) {
	function subscribe(e, gender) {
	// $('.subscribe input[name=email]').change(function() {

		e.preventDefault();
		// var el = $('.subscribe input[name=email]');
		var form_el = $(e.currentTarget).closest('form');
		var el = form_el.find('input[name=email]');
		if( el.attr('status')=='21' ) {
			if(gender === 'exit'){
				sendForgottenBasket( e, el.val() );
			}else if(gender === 'viewed'){
				sentExitPopup( e, el.val() );
			}else{
				sendAction(e, gender);
			}
			return false;
		}
		el.attr('status', 1);
		// $('.subscribe input[name=password]').hide('slow');
		form_el.find('.email-error').text('');
		form_el.find('.pass-error').text('');

		customer.checkEmail({
			email: el.val(),
			email_exists: function( msg ) {
				if(msg['customers_newsletter']==0){
					if(msg['customers_firstname']==''){
						$(el).attr('status', 5);
					}else{
						$(el).attr('status', 6);
					}
				}else if(msg['customers_newsletter']==1){
					$(el).attr('status', 7);
				}

				$(el).attr('cid', msg['customers_id']);

				if(gender === 'exit'){
					sendForgottenBasket( e, el.val() );
				}else if(gender === 'viewed'){
					sentExitPopup( e, el.val() );
				}else{
					sendAction(e, gender);
				}
			},
			email_not_exists: function( msg ) {
				$(el).attr('status', 2);
				if(gender == 'exit'){
					sendForgottenBasket( e, el.val() );
				}else if(gender === 'viewed'){
					sentExitPopup( e, el.val() );
				}else{
					sendAction(e, gender);
				}
			},
			error: function( msg ) {
				form_el.find('.email-error').text(msg.err_mes);
				$(el).attr('status', 0);
			},
			notification: function( msg ) {
				// $('.subscribe .success').text('�� ������� ����������� �� �������!');
			}
		});
		return false;
	};

	function sendAction(e, gender) {
		var form_el = $(e.currentTarget).closest('form');
		var email_el = form_el.find('input[name=email]');
		var create_source = 'site_form_subscribe';
		if( email_el.val().length==0 ) {
			email_el.focus();
			return false;
		}
		if( email_el.attr('status')=='0' ) {
			return false;
		}
		if( email_el.attr('status')=='1' ) {
			return false;
		}
		if( email_el.attr('status')=='2' ) {		//��������� ����� ������������ ���� email
			form_el.find('.email-holder').fadeOut(500,function(){
				form_el.find('.name-holder').show();
				form_el.find('input[name=name]').fadeIn(500, function(){
					customer.create({
						customer_info: {
							customers_email_address: form_el.find('input[name=email]').val(),
							customers_create_source: create_source,
							customers_gender: gender,
							customers_newsletter: 0,
							subscr: 'true',
						},
						success: function( msg ) {
							email_el.attr('status',21);
							email_el.attr('cid', msg['err_mes']);
							if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
							ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
						},
						error: function( msg ) {
						}
					});
				});
			});
			form_el.find('.submit_sucsribe_m label button').remove();
			form_el.find('.submit_sucsribe_f label button').remove();
			form_el.find('.submit_sucsribe_f label span').animate(
				{opacity: 1},
				500,
				function(){
					form_el.find('.submit_sucsribe_m label span');
					form_el.find('.submit_sucsribe_f label span').addClass('button');
					form_el.find('input[type=submit]').css("left","0");
				});
			email_el.attr('status',21);
			return false;
		}
		if( email_el.attr('status')=='5' ) {		//�� ����������� ������������ ������������ ��� ����� ���� email
			form_el.find('.email-holder').fadeOut(500,function(){
				form_el.find('.name-holder').show();
				form_el.find('input[name=name]').fadeIn(500, function(){});
			});
			form_el.find('.submit_sucsribe_m label button').remove();
			form_el.find('.submit_sucsribe_f label button').remove();
			form_el.find('.submit_sucsribe_f label span').animate(
				{opacity: 1},
				500,
				function(){
					form_el.find('.submit_sucsribe_f label span');
					form_el.find('.submit_sucsribe_f label span').addClass('button');
					form_el.find('input[type=submit]').css("left","0");
				});
	
			email_el.attr('status',21);
			return false;
		}

		if( email_el.attr('status') == '6' ) {		//�� ����������� ������������ ������������ � ������ ���� email
			form_el.find('.email-holder').fadeOut(500,function(){

				customer.updateCustomer({
					customer_info: {
						subscr: 'true',
						customers_id: email_el.attr('cid'),
						customers_gender: gender,
					},
					success: function( msg ) {
						form_el.find('.success').text('�������, ��������� E-MAIL � ����������� ���� ��������!');
					},
					error: function( msg ) {
					}
				});
				form_el.find('.email-holder').hide();
				form_el.find('.email-holder').css("left","0");
			});
			form_el.find('.submit_sucsribe_m').fadeOut(500);
			form_el.find('.submit_sucsribe_f').fadeOut(500);

			return false;
		}
		if( email_el.attr('status') =='7' ) {		//����������� ������������ ������������ 
			form_el.find('.email-holder').fadeOut(500,function(){
				form_el.find('.success').text('�� ��� ��������� �� ��������!');
			});
			form_el.find('.submit_sucsribe_m').fadeOut(500);
			form_el.find('.submit_sucsribe_f').fadeOut(500);
			return false;
		}
		if( email_el.attr('status')=='21' ) {

			form_el.find('.name-holder').fadeOut(500, function(){

				customer.updateCustomer({
					customer_info: {
						customers_firstname: form_el.find('input[name=name]').val(),
						// subscr: 'true',
						customers_id: email_el.attr('cid'),
					},
					success: function( msg ) {
						form_el.find('.success').text('�������, ��������� E-MAIL � ����������� ���� ��������!');
					},
					error: function( msg ) {
					}
				});
			});
			form_el.find('.submit_sucsribe_m').fadeOut(500);
			form_el.find('.submit_sucsribe_f').fadeOut(500);
			return false;
		}
	}

	//exit popup
	$(".submit_mail_for_cart").click(function( e ){
		subscribe( e, 'exit');
		return false;

	});


	$(".submit_mail_for_viewed").click(function( e ){
		subscribe( e, 'viewed');
		return false;

	});

	function sendForgottenBasket( e, email ){
		var create_source = 'exitPopup_WithBasket';
		var form_el = $(e.currentTarget).closest('form');
		var email_el = form_el.find('input[name=email]');
		var utm_term = 'exitPopup_withBasket_old';
		if($('.submit_mail_forgottenCart_new').length > 0){
			utm_term = "exitPopup_withBasket_new";
		}

		if( email_el.attr('status')=='0' ) {
			return false;
		}
		if( email_el.attr('status')=='1' ) {
			return false;
		}
		if( email_el.attr('status')=='2' ) {		//��������� ����� ������������ ���� email
			form_el.find('.email-holder').fadeOut(500,function(){
				form_el.find('.name-holder').show();
				form_el.find('input[name=phone]').show();
				form_el.find('input[name=name]').fadeIn(500, function(){
					customer.create({
						customer_info: {
							customers_email_address: email,
							customers_create_source: create_source,
							customers_newsletter: 1,
						},
						success: function( msg ) {
							email_el.attr('status',21);
							email_el.attr('cid', msg['err_mes']);
							customer.sendEmail({
								customer_info: {
									customers_email_address: email,
									letsSend: 'email',
									mail_type: 'forgotten_backet',
									customers_id: email_el.attr('cid'),
									utm_term: utm_term,
								},
								success: function( msg ) {
									email_el.attr('status',21);
									email_el.attr('cid', msg['err_mes']);
									form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������� �� �������.');
									ga('send', 'event', 'Popup', 'Popup_RecoveryCart', 'Popup_RecoveryCart_Sent_Email', {'nonInteraction': 1});
								},
								error: function( msg ) {
								}
							});
							if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
							ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
						},
						error: function( msg ) {
						}
					});
				});
			});
			form_el.find('.submit_mail_for_cart label button').remove();
			form_el.find('.submit_mail_for_cart label span').animate(
				{opacity: 1},
				500,
				function(){
					if($('.submit_mail_for_cart').length > 0){
						form_el.find('.submit_mail_for_cart label span').closest('.submit_mail_for_cart')/*.addClass('mrgTop46')*/;
					} else {
						form_el.find('.submit_mail_for_cart label span');
					}
					// form_el.find('.submit_mail_for_cart').css("margin","0 0 0 235px");
					// form_el.find('input[type=submit]').css("left","0");
				});
			email_el.attr('status',21);
			return false;

		}else if(email_el.attr('status')==5 || email_el.attr('status')==6 || email_el.attr('status')==7){		//������������ � email
			customer.sendEmail({
				customer_info: {
					customers_email_address: email,
					letsSend: 'email',
					mail_type: 'forgotten_backet',
					customers_id: email_el.attr('cid'),
					utm_term: utm_term,
				},
				success: function( msg ) {
					form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������� �� �������.');
					ga('send', 'event', 'Popup', 'Popup_RecoveryCart', 'Popup_RecoveryCart_Sent_Email', {'nonInteraction': 1});
				},
				error: function( msg ) {
				}
			});
			form_el.find('.email-holder').hide();
			form_el.find('.submit_mail_for_cart').hide();
			form_el.find('.email-holder').css("left","0");
			return false;
		}if( email_el.attr('status')=='21' ) {
			customer.updateCustomer({
				customer_info: {
					customers_firstname: form_el.find('input[name=name]').val(),
					customers_telephone: form_el.find('input[name=phone]').val(),
					// subscr: 'true',
					customers_id: email_el.attr('cid'),
				},
				success: function( msg ) {
					form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������� �� �������.');
				},
				error: function( msg ) {
				}
			});
			form_el.find('.name-holder').remove();
			form_el.find('.submit_mail_for_cart').fadeOut(500);
			return false;
		}
	}

	function sentExitPopup( e, email ){
		var create_source = 'exitPopup_WithBasket';
		var form_el = $(e.currentTarget).closest('form');
		var email_el = form_el.find('input[name=email]');
		var utm_term = 'old';
		if($('.submit_mail_for_cart_new').length > 0){
			utm_term = "new";
		}

		if( email_el.attr('status')=='0' ) {
			return false;
		}
		if( email_el.attr('status')=='1' ) {
			return false;
		}
		if( email_el.attr('status')=='2' ) {	//��������� ����� ������������ ���� email
			form_el.find('.email-holder').fadeOut(500,function(){
				form_el.find('.name-holder').show();
				form_el.find('input[name=phone]').show();
				form_el.find('input[name=name]').fadeIn(500, function(){
					customer.create({
						customer_info: {
							customers_email_address: email,
							customers_create_source: create_source,
							customers_newsletter: 1,
						},
						success: function( msg ) {
							email_el.attr('status',21);
							email_el.attr('cid', msg['err_mes']);
							customer.sendEmail({
								customer_info: {
									customers_email_address: email,
									letsSend: 'email',
									mail_type: 'sent_mail_viewed_products',
									customers_id: email_el.attr('cid'),
									href: email_el.attr('data-href'),
								},
								success: function( msg ) {
									email_el.attr('status',21);
									email_el.attr('cid', msg['err_mes']);
									form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������������� ��������.');
									ga('send', 'event', 'Popup', 'Popup_ViewedProducts', 'ViewedProducts_Sent_Email', {'nonInteraction': 1});
								},
								error: function( msg ) {
								}
							});
							if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
							ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
						},
						error: function( msg ) {
						}
					});
				});
			});
			form_el.find('.submit_mail_for_viewed label button').remove();
			form_el.find('.submit_mail_for_viewed').addClass('second_step');
			form_el.find('.close_popup').addClass('second_step');
			form_el.find('.submit_mail_for_viewed label span').animate(
				{opacity: 1},
				500,
				function(){
					if($('.submit_mail_for_cart_new').length > 0){
						form_el.find('.submit_mail_for_viewed label span').closest('.submit_mail_for_cart_new').addClass('mrgTop46');
					} else {
						form_el.find('.submit_mail_for_viewed label span');
					}
					form_el.find('.submit_mail_for_viewed label span.btn-orange').css("margin","0 0 0 235px");
					// form_el.find('input[type=submit]').css("left","0");
				});
			email_el.attr('status',21);
			return false;

		}else if(email_el.attr('status')==5 || email_el.attr('status')==6 || email_el.attr('status')==7){		//������������ � email
			customer.sendEmail({
				customer_info: {
					customers_email_address: email,
					letsSend: 'email',
					mail_type: 'sent_mail_viewed_products',
					customers_id: email_el.attr('cid'),
					utm_term: utm_term,
					href: email_el.attr('data-href'),
				},
				success: function( msg ) {
					form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������������� ��������.');
					form_el.find('.close_popup').addClass('second_step');
					ga('send', 'event', 'Popup', 'Popup_ViewedProducts', 'ViewedProducts_Sent_Email', {'nonInteraction': 1});
				},
				error: function( msg ) {
				}
			});
			form_el.find('.email-holder').hide();
			form_el.find('.submit_mail_for_viewed').hide();
			form_el.find('.email-holder').css("left","0");
			return false;
		}if( email_el.attr('status')=='21' ) {
			customer.updateCustomer({
				customer_info: {
					customers_firstname: form_el.find('input[name=name]').val(),
					customers_telephone: form_el.find('input[name=phone]').val(),
					// subscr: 'true',
					customers_id: email_el.attr('cid'),
				},
				success: function( msg ) {
					form_el.find('.success').text('�������! �� ���� ����� ���������� ������ � �������������� ��������.');
				},
				error: function( msg ) {
				}
			});
			form_el.find('.name-holder').remove();
			form_el.find('.submit_mail_for_viewed').fadeOut(500);
			return false;
		}

	}

})();

// BOF subscription popup
$('body').popup({
	"opener":'#notifications_',
	"popup_holder":"#subscription",
	"popup":".popup",
	"close_btn":".btn-close1, .btn-sgray",
});
$("#notifications_").click(function( e ){
	e.preventDefault();
	$(this).closest('form').submit();
});

$('#subscription').on('submit', 'form', function( e, submit ) {
	e.preventDefault();
	var form = $(this);
	var create_source = $('input[name=create_source]', this).val();
	$('.validation-description', $(this)).html('');
	var name_el = form.find('input[name=name]', this);
	var email_el = form.find('input[name=email_address]', this);
	var pass_el = form.find('input[name=password]', this);
	var error = false;
	var email_status = email_el.data('status');
	if( email_status == 0 ) {
		email_el.trigger('change', 1);
	} else if( email_status == 1 ) {
		customer.create({
			customer_info: {
				customers_email_address: email_el.val(),
				customers_firstname: name_el.val(),
				customers_create_source: create_source,
				subscr: 'true',
			},
			success: function( msg ) {
				el.data('status', 3);
				form.data('disabled', 0).trigger('submit', 1);
				if( window['yaCounter' + window.PROSKTR.yaCounterId] ) window['yaCounter' + window.PROSKTR.yaCounterId].reachGoal(create_source);
				ga('send', 'event', 'email', 'subcribe', create_source, {'nonInteraction': 1});
			},
			error: function( msg ) {
				el.data('status', 1);
				email_el.closest('.value').find('.validation-description').html( msg.err_mes );
			}
		});
	} else if( email_status == 2 ) {
		el.data('status', 4);
		customer.login({
			customerInfo: {
				email: email_el.val(),
				pass: pass_el.val()
			},
			success: function( msg ) {
				email_el.data('status', 3);
				form.data('disabled', 0).trigger('submit', 1);
			},
			error: function( msg ) {
				el.data('status', 2);
				pass_el.closest('.value').find('.validation-description').html( msg.err_mes );
			}
		});
	}
});
$('#subscription').on('click', '.pass-forgotten', function( e ) {
	e.preventDefault();
	customer.sendNewPass({email_address: $(this).closest('form').find('input[name=email_address]').val() }, function( msg ) {$(this).closest('.value').find('.validation-description').html(msg); } );
});
$('#subscription').on('change', 'input[name=email_address]', function( e, submit ) {
	var form = $(this).closest('form');
	var el = $(this);
	el.data('status', 4);
	var hint = el.closest('.row').find('.validation-description').html('');
	customer.checkEmail({
		email: this.value,
		email_exists: function( msg ) {
			form.find('input[name=password]', form).data('required', true).closest('.row').show();
			form.find('input[name=name]', form).removeData('required').closest('.row').hide();
			el.data('status', 2);
		},
		email_not_exists: function( msg ) {
			form.find('input[name=password]', form).removeData('required').closest('.row').hide();
			form.find('input[name=name]', form).data('required', true).closest('.row').show();
			el.data('status', 1);
			if( submit == 1 ) {
				form.submit();
			}
		},
		error: function( msg ) {
			hint.html( msg.err_mes );
			el.data('status', 0);
		},

	});
});
$('#subscription').on('click', '.btn-close-popup', function( e ) {
	$('#subscription .btn-close1').trigger('click');
	e.preventDefault();
});
// EOF subscription popup
