// http://javascript-minifier.com/
var autocomplete_city_cache = { byCity: {}, byZoneId: {} };
$.fn.redrawMySelect = function() {
	return;
}
var defaultValidateSetup = {
	description : {
		commonDescription : {
			required : '<div class="error">���� ����������� ��� ����������</div>',
			rus : '<div class="error">����� ������ ���� �� ������� �����</div>',
			name : '<div class="error">�������� ������</div>',
			fullname : '<div class="error">��������� � �������: "��� �������" �������� �������</div>',
			email : '<div class="error">�� ����� ����� e-mail</div>',
			phone_10 : '<div class="error">�� ����� ����� ����� ��������</div>',
			phone_11 : '<div class="error">�� ����� ����� ����� ��������</div>',
			pass : '<div class="error">������ ������ ��������� ������� 5 ��������</div>',
			passConfirm : '<div class="error">���� "���������� ������" � "��������� ������" ������ ���������</div>',
			valid : '<div class="success">��</div>',
			autocompleteCity : '<div class="error">�� ������ �����</div>',
			numeric : '<div class="error">�������� ������</div>',
			dateRu : '<div class="error">�������� ������ ���� (��.��.����)</div>',
		}
	},
	eachInvalidField: function( event, statuses, options ) {
		var dataDescription = $(this).data('description') || 'commonDescription';
		var text = '';
		$.each( statuses, function(i, val) {
			if ( val == false ) {
				if( i == 'conditional' ) {
					text = options.description[ dataDescription ][ statuses.conditionalName ] || options.description[ 'commonDescription' ][ statuses.conditionalName ] || '';
				} else {
					text = options.description[ dataDescription ][ i ] || options.description[ 'commonDescription' ][ i ] || '';
				}
			}
		} );
		var fieldDescribedby = $(this).data('describedby');
		$(this).addClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html(text);
		} else {
			$(this).closest('.j-value').find('.validation-description').html(text);
		}
	},
	eachValidField : function() {
		fieldDescribedby = $(this).data('describedby');
		$(this).removeClass('valid-error');
		if( fieldDescribedby != undefined && $('#'+fieldDescribedby).length ) {
			$('#'+fieldDescribedby).html('');
		} else {
			$(this).closest('.j-value').find('.validation-description').html('');
		}
	},
	invalid: function() {
		$(this).addClass('form-valid-error');
		$('.sc-form-error').show();
		setTimeout(function() { $('.sc-form-error').hide(); }, 2000);
	},
	valid: function() {
		$(this).removeClass('form-valid-error');
	},
	conditional: {
		rus: function( value, options ) {
			return value.length == 0 || /^[���-��-�\s\-,]+$/.test(value);
		},
		name: function( value, options ) {
			return value.length == 0 || /^[���-��-�a-zA-Z\s\-,]+$/.test(value);
		},
		fullname: function( value, options ) {
			return value.length == 0 || /^[���-��-�a-zA-Z\s\-,]+ [���-��-�a-zA-Z\s\-,]+$/.test(value);
		},
		email: function( value, options ) {
			return value.length == 0 || /[-0-9a-z_]+@[-0-9a-z_^.]+\.[a-z]+/i.test(value);
		},
		phone_11: function( value, options ) {
			value = value.replace(/[^0-9]/g,"");
			return value.length==PROSKTR.country.phoneLength || value.length==0;
		},
		pass: function( value, options ) {
			return value.length>=5;
		},
		passConfirm: function( value, options ) {
			var passEl = $(this).closest('form').find('input[name=password]');
			return value == passEl.val();
		},
		autocompleteCity: function( value, options ) {
			var holder = $(this).closest('.city-holder');
			var city = $('input[name=city]', holder);
			var zone_id = $('[name=zone_id]', holder);
			return parseInt(zone_id.val()) > 0 && city.val().length > 0;
		},
		numeric: function( value, options ) {
			return !isNaN( value.trim() );
		},
		ajaxEmail: function( value, options, a ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var status = $(this).data('validEmailStatus');
			var phoneEl = $(this).closest('form').find('input[name=phone]');
			if (phoneEl.length == 0) {
				phoneEl = $(this).closest('form').find('input[name=telephone]');
			}
			if ( phoneEl.length > 0 && phoneEl.val().length > 0 ) {
				status = 3;
				$(this).data('validEmailStatus', status);
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( lastVal != $(this).val() ) {
				$(this).data('lastVal', $(this).val());
				status = 0;
			}
			if( status == 1 || status == 3 || customer.isLogin() ) {
				if( !$(this).closest('form').hasClass('on-change-validate-form') ) {
					return true;
				}
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && this.val() == this.data('old') ) {
				return true;
			}
			var emailEl = this;
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-email') ) {
				pass_btn.data('login-binded-email', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: emailEl.val(),
							},
							error: function(msg) {
								if ( phoneEl.length == 0 || phoneEl.val().length == 0 ) {
									emailEl.data('validEmailStatus', 0);
									passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
								}
							},
							success: function(msg) {
								emailEl.data('validEmailStatus', 3);
								if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
									window.location.reload();
								}
							}
						}
					);
				});
			}
			customer.checkEmail({
				email: value,
				email_exists: function( msg ) {
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � email');
					$(emailEl).data('validEmailStatus', 2);
					var moreText = '. ���� �� ������ ������, �������� �����.';
					if ( emailEl.hasClass('registerMail') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					$(emailEl).closest('.j-value').find('.validation-description').html('<div class="error">��� e-mail ��� ���������������' + moreText + '</div>');
				},
				email_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !phoneEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(emailEl).data('validEmailStatus', 1);
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !phoneEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(emailEl).data('validEmailStatus', 0);
				}
			});
			return false;
		},
		ajaxPhone: function( value, options, a ) {
			// email_statuses: 
			// 0 - not_checked / checking / error
			// 1 - checked - email_not_exists
			// 2 - checked - email_exists
			// 3 - logined
			// 4 - process
			var emailEl = $(this).closest('form').find('input[name=email_address]');
			var status = $(this).data('validPhoneStatus');
			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( lastVal != $(this).val() ) {
				$(this).data('lastVal', $(this).val());
				status = 0;
			}
			var phoneEl = this;
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				return true;
			}
			if( status == 1 ) {
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=password]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			if( !pass_btn.data('login-binded-phone') ) {
				pass_btn.data('login-binded-phone', true);
				pass_btn.on('click', function() {
					customer.login(
						{
							customerInfo: {
								pass: passEl.val(),
								email: phoneEl.val(),
								options: {'email_or_phone':true},
							},
							error: function(msg) {
								phoneEl.data('validPhoneStatus', 0);
								passRow.find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>').addClass('not-empty');
							},
							success: function(msg) {
								phoneEl.data('validPhoneStatus', 3);
								if( PROSKTR.content == 'shopping_cart' && msg.count_contents_before != msg.count_contents_after ) {
									window.location.reload();
								}
							}
						}
					);
				});
			}
			customer.checkPhone({
				phone: value,
				phone_exists: function( msg ) {
					passRow.removeClass('hidden');
					passRow.find('.form-control__placeholder').text('������ � ��������');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '. ���� �� ������ ������, �������� �����.';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab greydotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login greydotted">����� � ���� �������</a>';
					}
					$(phoneEl).closest('.j-value').find('.validation-description').html('<div class="error">��� ������� ��� ���������������' + moreText + '</div>');
				},
				phone_not_exists: function( msg ) {
					if( $(this).closest('form').hasClass('on-change-validate-form') ){
						phoneEl.data('validPhoneStatus', 3);
						return true;
					}
					if( $(phoneEl).closest('form').attr('name') == 'create_account' ) {
						// $(phoneEl).closest('form').submit();
					}
					if ( passRow.closest('#create-account-form').length == 0 && !emailEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(phoneEl).data('validPhoneStatus', 1);
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 && !emailEl.hasClass('valid-error') ) {
						passRow.addClass('hidden');
					}
					$(this).closest('.j-value').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
				}
			});
			return false;
		},
		ajaxPhoneAndPassCode: function( value, options ) {
			var phoneEl = this;
			$(phoneEl).closest('form').find('.toOrder').removeClass('j-s-sbmt').addClass('disabled');
			if ( $(phoneEl).data('proccess') > 0 ) {
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if ( value.length > 0 ) {
				$(phoneEl).data('proccess', 1);
			}
			var status = $(this).data('validPhoneStatus');

			if( status == undefined ) {
				status = 0;
			}
			var lastVal = $(this).data('lastVal');
			if( typeof lastVal == 'string' ) {
				lastVal = parseInt(lastVal.replace(/\D+/g,""));
			}
			var parsedVal = parseInt($(this).val().replace(/\D+/g,""));
			if( lastVal != parsedVal ) {
				$(this).data('lastVal', parsedVal);
				status = 0;
			}
			if( $(this).closest('form').hasClass('on-change-validate-form') && ( parseInt(this.val().replace(/\D+/g,"")) == this.data('old') || status == 1) ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if( status == 1 ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			if( customer.isLogin() && customer.info.phone == parsedVal ) {
				$(phoneEl).data('proccess', 0);
				// $(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt');
				return true;
			}
			var passEl = $(this).closest('form').find('input[name=passCode]');
			var passRow = passEl.closest('.pass-row');
			var pass_btn = $('.pass-btn', passRow);
			$(phoneEl).removeClass('exists');

			customer.checkPhoneAndSendPassCode({
				phone: value,
				phone_exists: function( msg ) {
					if( msg.phoneThisLogin == 1 ) {
						$(phoneEl).data('validPhoneStatus', 1);
						$(phoneEl).trigger('change');
						$(phoneEl).data('proccess', 0);
						return true;
					}
					passRow.removeClass('d-none');
					$(phoneEl).closest('form').find('.toOrder').removeClass('j-s-sbmt').addClass('disabled');
					$(phoneEl).data('validPhoneStatus', 2);
					var moreText = '';
					if ( phoneEl.hasClass('fastOrderPhone') ) {
						moreText = ' <a class="toLoginTab dotted">����� � ������</a>';
					}
					if ( phoneEl.hasClass('registerPhone') ) {
						moreText = ' <a class="js-login dotted">����� � ���� �������</a>';
					}
					$(phoneEl).addClass('exists').closest('.form-group').find('.validation-description').html('<div class="exists">' + msg.err_mes + '</div>');
					$(phoneEl).data('proccess', 0);
				},
				phone_not_exists: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
					}
					$(phoneEl).data('validPhoneStatus', 1);
					$(phoneEl).trigger('change');
					$(phoneEl).data('proccess', 0);
					$(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt').removeClass('disabled');
				},
				error: function( msg ) {
					if ( passRow.closest('#create-account-form').length == 0 ) {
						passRow.addClass('d-none');
						$(phoneEl).closest('form').find('.toOrder').addClass('j-s-sbmt').removeClass('disabled');
					}
					$(phoneEl).closest('.form-group').find('.validation-description').html('<div class="error">' + msg.err_mes + '</div>');
					$(phoneEl).data('validPhoneStatus', 0);
					$(phoneEl).data('proccess', 0);
				}
			});
			return false;
		},
		dateRu: function( value, options ) {
			if( !/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(value) ) {
				return false;
			} else {
				var arrD = value.split(".");
				arrD[1] -= 1;
				var d = new Date(arrD[2], arrD[1], arrD[0]);
				if ((d.getFullYear() == arrD[2]) && (d.getMonth() == arrD[1]) && (d.getDate() == arrD[0])) {
					return true;
				} else {
					return false;
				}
			}
		},
		'autocomplete-city': function( value, option ) {
			if( $(this).is(':disabled') ) {
				return true;
			}
			if( value.length == 0 || value == 0 ) {
				$(this).closest('.for-autocomplete').parent().selectCity('showError', '�� ����� �����');
				return false;
			} else {
				return true;
			}
		}
	}
};
var default_ajax_form_options = {
	dataType: 'json',
	timeout: 15000,
	beforeSubmit: function(formData, jqForm, options, form) {
		if( jqForm.data('zone_id_check') == 1 && array_search( formData, 'zone_id' ).value == 0 ) {
			return false;
		}
		if( jqForm.data('disabled') == 1 ) {
			return false;
		}
		jqForm.addClass('form-loading');
		formData.push({name: 'ajax', value: 3});
	},
	success: function(responseText, statusText, additParam, form) {
		form.removeClass('form-loading');
		if( responseText.data ) {
			$.each( responseText.data, function( selector, data ) {
				el = $( selector );
				el.html( data );
				if( $('form.validate', el) ) {
					$('form.validate', el).validate();
				}
				$('.datepicker', el).datepicker();
				if( $('.default-validate-form', el).length ) {
					$('.default-validate-form', el).validate();
				}
				$('.default-ajax-form', el).ajaxForm( default_ajax_form_options );
				$('.phone-11-dig', el).phoneMask();
				var checkboxes = $('input:checkbox', el);
				if( checkboxes.length ) {
					checkboxes.checkbox();
				}
				if( selector == '.delivery-list' ) {
					var select_city = $('.country-form input[name=city]').val();
					var uri_name = $('#shops-popup select[name=shop_city] option[city='+select_city+']').val();
					$('#shops-popup select[name=shop_city]').val(uri_name).trigger('change');
				}
				$('.tooltip', el).tooltip_init();
				$('.tabset', el).tabset();
				$('.subtabset').tabset({
					control: '.subtabs .subtab span',
					add_parent_active : 'li',
					tab : '.subtab-item',
					hash : false
				});
				$(document).on('click', '.btn-see-map', function( e, delivery ) {
					e.preventDefault();
					var form = $(this).closest('form');
					$('input[name=filtrDelivery]', form).val( delivery == undefined ? '' : delivery );
					var tmp_options = form.serializeArray();
					var options = {};
					for (var i = 0; i < tmp_options.length; i++) {
						options[ tmp_options[i].name ] = tmp_options[i].value;
					}
					$('#see-map').trigger('click');
					ymaps.ready(function() {
						$('#map-popup').shopsMap( options );
					});
				});
				$(".delivery-text .tab").click(function(){
					$(".delivery-text .tab").removeClass("active").eq($(this).index()).addClass("active");
					$(".delivery-text .tab-item").hide().eq($(this).index()).fadeIn()
				}).eq(0).addClass("active");
				init_sc_autocomplete( el );
				// el.find('.fancybox').fancybox();
				if( $.inArray('alignPopup', responseText.options) >= 0 ) {
					var popup = el.closest('.popup');
					if( popup.length ) {
						popup.css({
							'top': (popup.outerHeight() - $(window).height() >= 20 ? 0 : (($(window).height()-popup.outerHeight())/2) - 10 ),
							// 'left': (($(window).width() - popup.outerWidth())/2) + $(window).scrollLeft()
						});
					}
				}
			});
		}
	}
};
if ( typeof $.validateSetup === 'function' )
{
	$.validateSetup(defaultValidateSetup);
}
$.views.helpers({
	json_decode: function( val ) {
		return $.toJSON(val);
	},
});

function listingCheckCoupon(el) {
	return;
	let $form = el;
	var data = {};
	data.products_id = $('input[name=products_id]', $form).val();
	if ( $('input[name="id[1]"]', $form).val() != '' ) {
		data.fullAttrProduct = data.products_id + '{1}' + $('input[name="id[1]"]', $form).val();
		if ( $('input[name="id[2]"]', $form).val() != '' ) {
			data.fullAttrProduct = data.fullAttrProduct + '{2}' + $('input[name="id[2]"]', $form).val();
		}
	}
	data.action = 'recalc_price';
	$.ajax({
		type: "GET",
		url: "/ajax_fetch_coupons.php",
		data: data,
		dataType: "json",
		success: function(msg) {
			if ( msg.start_price > msg.price ) {
				let pct = 100 - Math.round((msg.price / msg.start_price * 100));
				let _html = msg.price + ' ' + window.PROSKTR.currencies.right + '.';
				_html = _html + ' (-' + pct + '%)';
				if ( $form.closest('.js-listing-product').find('.tab.active').length ) {
					$('.tab.active .cardMin-wt input[type=radio]:checked+label .price', $form.closest('.js-listing-product')).html(_html);
				} else {
					$('.cardMin-wt input[type=radio]:checked+label .price', $form.closest('.js-listing-product')).html(_html);
				}
			}
		}
	});
}

Customer.prototype.logoff = function( callback ) {
	$.ajax({
		url: '/ajax_customer.php?action=logoff&ajax=1',
		type: 'POST',
		dataType: 'json',
		context: this,
		success: function( msg ) {
			if( msg.result==1 ) {
				cookieObj.setCookie('customerid', 0 );
				this.info = {};
				shoppingCart.reloadPopup();
				var headerElement = $('.meta-nav__menulist__item.account');
				headerElement.removeClass('logined');
				$('.mobile-nav__tools .account').hide();
				$('.meta-nav__menulist__link span', headerElement).text('�����');
				$('.meta-nav__menulist__link', headerElement).attr('data-toggle','modal').attr('data-target','#login-modal');

				if( typeof callback == 'function' ) {
					callback( msg );
				}
			}
		}
	});
};

Customer.prototype.create = function( data ) {
	var sendData = data.customer_info;
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax_customer.php?action=create&ajax=1',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function( msg ) {
			$('.login-block').html('<a href="/logoff.php" class="logout-btn">�����</a><a href="/account.php" class="orange">�������</a>');
			shoppingCart.reloadPopup();
			this.logined = true;
			this.info.email = data.customer_info.customers_email_address;
			this.info.customer_id = parseInt(msg.err_mes);
			this.info.asLastOrder = false;
			this.info.name = msg.name;
			this.info.phone = msg.phone;
			this.info.lastname = msg.lastname;
			if ( window.PROSKTR.content == 'b2b' ) {
				window.location.reload();
			}
			if( typeof this.onLogin == 'function' ) {
				this.onLogin();
			}
			this.callUserFunc( data, msg );
		}
	});
};

Customer.prototype.login = function( data, callback ) {
	var sendData = data.customerInfo;
	if( window.PROSKTR.ajax_csrf_token !== undefined ) {
		sendData.csrf_token = window.PROSKTR.ajax_csrf_token;
	}
	$.ajax({
		url: '/ajax_customer.php?action=check&ajax=1',
		type: 'POST',
		data: sendData,
		dataType: 'json',
		context: this,
		success: function( msg ) {
			this.callUserFunc( data, msg );
			if( msg.result==1 && msg.err_code=='login_ok' ) {
				var headerElement = $('.meta-nav__menulist__item.account');
				headerElement.addClass('logined');
				$('.mobile-nav__tools .account').show();
				if ( msg.name )
				{
					var name = msg.name.split( ' ' );
					name = name[0];
					$('.meta-nav__menulist__link span', headerElement).text(name);
				}
				shoppingCart.reloadPopup();
				if(msg.customerNeedConfirm != undefined){
					$('body').append(msg.customerNeedConfirm).popup().popup({
						"start":true,
						"opener":false,
						"popup_holder":"#customerNeedConfirm",
					});
				}
				this.logined = true;
				this.info.email = data.customerInfo.email;
				this.info.customer_id = parseInt(msg.customers_id);
				this.info.asLastOrder = msg.may_last_order;
				this.info.name = msg.name;
				this.info.phone = msg.phone;
				this.info.lastname = msg.lastname;
				if( typeof this.onLogin == 'function' ) {
					this.onLogin();
				}
				if( typeof callback == 'function' ) {
					callback( msg );
				}
				cookieObj.setCookie('customerid', parseInt(msg.customers_id) );
				if($('.login-btn_trigger_reload').length > 0){
					location.reload();
				}
			}
		}
	});
};

Listing.prototype.settings.formFiltrs = '.filter-item';
Listing.prototype.listAjaxBefore = function() {
	$('.catalog-products-count').addClass('loading');
	$('.mobile-loading').addClass('loading');
	$('.product-list').addClass('loading');
};
Listing.prototype.settings.formShowResponse = function(responseText, statusText, additParam, jqForm) {
	jqForm.removeClass('loading');
	$('.js-product-list').removeClass('loading');
	// $('.catalog-products-count').removeClass('loading');
	// $('.mobile-loading').removeClass('loading');
	// $('.more-btn').removeClass('loading');
	if( $('#show_more').val() == 0 ) {
		$('.js-product-list').empty();
	}

	$('.count-products').html( responseText.productsCount );
	var productsList = [];

	for(var product of responseText.productsList){
		if(product.cattovara_main == '���' && product.products_images_count>1){
			product.imgGallery = [];
			for (var i=1; i<=product.products_images_count; i++){
				product.imgGallery.push({
					key:i,
					products_image: product.products_image.replace("1.", i+"."),
					products_name: product.products_name
				});
			}
		}
		productsList.push(product);
	}

	var productsHtml = $.render[ 'listing_product' ].render( productsList, { currencies: window.PROSKTR.currencies } );
	// $('.catalog-products-section .catalog-grid .psuedo-item').remove();
	$('.js-product-list').append(productsHtml);
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');
	// $('.catalog-products-section .catalog-grid').append('<div class="catalog-grid-item psuedo-item"></div>');

	$('#show_more').val( 0 );
	window.listing.loading = false;
	if( window.listing.queue ) {
		window.listing.queue = false;
		$('#filtr-form').trigger('change');
	}
	window.listing.setFiltrs( responseText.filtrs );
	$('.catalog-products-count').html( responseText.pagination.allItems + NumToRu_declination(responseText.pagination.allItems, ' �����', ' ������', ' �������') );
	$('.reset-btn').css('visibility', $('.filter-holder>.multiselect-enabled.active').length == 0 ? 'hidden' : 'visible');
	var filtrs_checked = 0;
	$.each(responseText.filtrs, function( filtrId, filtrInfo ) {
		filtrs_checked += filtrInfo.info.checked !== undefined ? +filtrInfo.info.checked : 0;
	});
	if( filtrs_checked > 1 ) {
		$('.no-products').show();
	} else {
		$('.no-products').hide();
	}
	$('.productlist-paging').html( responseText.pagination.html );
	// $('.productlist-paging')[ responseText.pagination.html.length ? 'show' : 'hide' ]();
	if ( responseText.pagination.isLast ) {
		$('.loadmore').addClass('hidden')
	} else {
		$('.loadmore').removeClass('hidden')
	}
	setFiltersSize();
	$('.product-list').imagesLoaded(function(){
		$('.product-list .listing-product-item').matchHeight();
	});

	var productsArr = [];
	var totalPrice = [];
	for (var i = responseText.productsList.length - 1; i >= 0; i--) {
		productsArr.push( '' + responseText.productsList[i].products_id );
		totalPrice.push( responseText.productsList[i].current_price );
	}

	$(".product-list .tabset").each(function(){
		$(this).tabset();
	});
	$('[data-toggle="tooltip"]').each(function(){
		$(this).tooltip({html:true})
	});
	$('.productlist .product-list .js-add-product-form').each(function(){
		listingCheckCoupon($(this));
	});
};
Listing.prototype.hideFiltrs = function() {
	var div = $(this.options.divFiltrs, this.options.form);
	var filtrElements = $(this.options.formFiltrs, this.options.form);
	var elHeight = filtrElements.eq(0).outerHeight(true);
	var togglerAdded = false;
	var toggler = $(this.options.divFiltrsToggler, this.options.form);
	if( !div.is(':visible') ){
		$(this.options.divFiltrsToggler, this.options.form).hide();
		div.closest('.one-row').removeClass('one-row');
		return false;
	} else if (div.height() <= elHeight + 10) {
		$(this.options.divFiltrsToggler, this.options.form).hide();
		div.closest('.one-row').removeClass('one-row');
		return false;
	}
	while( div.height() > elHeight + 10 ) {
		if( !togglerAdded ) {
			togglerAdded = true;
			toggler.css('display', 'inline-block');
		}
		toggler.after(filtrElements.last().hide().addClass('toggled'));
		filtrElements = filtrElements.filter(':visible');
	}
	div.closest('.one-row').removeClass('one-row');
};

ShoppingCart.prototype.addProductCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		var cart_info = $('.drop-cart');
		var that = this;
		this.setQuantityNPrice( msg );
		if( msg.dublicate ) {
		} else {
			this.reloadPopup();
			if (typeof data.success == 'function') {
				data.success.call(this, msg);
			}
			addProductEvent( msg );
			if ( $('.product-cart').length && msg.added_product[0].products_id == $('.product-cart').data('productsId') ) {
				$('.j-show-add-product-modal').trigger('click');
			}

			if ( window.PROSKTR.content == 'index_products' ) {
				$('.j-inCart').text('0').addClass('d-none');
				let inCart = new Object();
				for (var i = msg.products.length - 1; i >= 0; i--) {
					if ( inCart[msg.products[i]['products_id']] == undefined ) {
						inCart[msg.products[i]['products_id']] = parseInt(msg.products[i]['quantity']);
					} else {
						inCart[msg.products[i]['products_id']]  = parseInt(inCart[msg.products[i]['products_id']]) + parseInt(msg.products[i]['quantity']);
					}
				}
				for (const [key, value] of Object.entries(inCart)) {
					$('.j-inCart[data-pid=' + key + ']').text(value).removeClass('d-none');
				}
				$('.plus-btn').removeClass('loading');
			}
		}
	}
}

ShoppingCart.prototype.reloadPopupCallback = function() {
	return function( msg, textStatus, jqXHR ) {
		var that = this;
		$.each(msg.productsList, function( key, value ) {
			if( value.id == that.lastAddedProduct ) {
				value.lastAdded = true;
			}
		});
		var cart = $('.basket-aside');
		$('.cart-content-count').text(msg.count_contents);
		$('.drop-cart-list', cart).html($.render[ 'shopping_cart_popup_product' ].render(msg.productsList));
		if( msg.count_contents > 0 ) {
			$('.header-cart').addClass('basket-opener').closest('li').addClass('not-empty');
		} else {
			$('.header-cart').removeClass('basket-opener').closest('li').removeClass('not-empty');
		}
	}
}

ShoppingCart.prototype.deleteProductCallback = function( data ) {
	var _this = this;
	return function( msg, textStatus, jqXHR ) {
		shoppingCart.reloadPopup();
		_this.setQuantityNPrice( msg );
		if( typeof data.products_id == 'string' ) {
			data.products_id = [ data.products_id ];
		}
		$.each( data.products_id, function(key,products_id) {
			$('.delete-form [name="products_id[]"][value="' + products_id + '"]').closest(".cart-list__row").fadeOut();
			$('.orders-list .orders-list__item[data-id="' + products_id + '"]').fadeOut();
		} );
		if (typeof data.success == 'function') {
			data.success.call(_this, msg);
		}
		deleteProductEvent(msg);

		if ( window.PROSKTR.content == 'index_products' ) {
			$('.j-inCart').text('0').addClass('d-none');
			let inCart = new Object();
			for (var i = msg.products.length - 1; i >= 0; i--) {
				if ( inCart[msg.products[i]['products_id']] == undefined ) {
					inCart[msg.products[i]['products_id']] = parseInt(msg.products[i]['quantity']);
				} else {
					inCart[msg.products[i]['products_id']]  = parseInt(inCart[msg.products[i]['products_id']]) + parseInt(msg.products[i]['quantity']);
				}
			}
			for (const [key, value] of Object.entries(inCart)) {
				$('.j-inCart[data-pid=' + key + ']').text(value).removeClass('d-none');
			}
		}
	}
};

ShoppingCart.prototype.setQuantityNPrice = function( msg ) {
	var cart_info = $('.basket-aside');
	msg = msg || false;
	// �� �������� data
	if( msg ){
		var total = msg.total_price_formatted ? msg.total_price_formatted : msg.total;
		$('.price', cart_info).html( total + ' ' + window.PROSKTR.currencies.right );
		$('.cart-total').html( total + ' ' + window.PROSKTR.currencies.right );
	}
	if ( window.PROSKTR.content != 'shopping_cart' ) {
		return;
	}
	// �� �������� data
	if( msg ){
		var total = msg.total_price_formatted ? msg.total_price_formatted : msg.total;
		$('.price', cart_info).html( total + ' ' + window.PROSKTR.currencies.right );
		$('.cart-total').html( total + ' ' + window.PROSKTR.currencies.right );
		$('.js-allproductstotal-text').html( msg.allProductsTotal + ' ' + window.PROSKTR.currencies.right );
		$('.js-coffee-weight').html( Math.floor(msg.coffeeWeight/100)/10 );
		$('.js-allproductsdiscount-text').html( msg.allProductsTotalDiscount + ' ' + window.PROSKTR.currencies.right );
		$('.cart-total-discount').html( ( msg.real_total - total ) + ' ' + window.PROSKTR.currencies.right );
		$('.cart-content-count').html(msg.count_contents);

		$('.side-products-cost').data('value', msg.total).html(total + ' ' + window.PROSKTR.currencies.right);
		$('.side-discount').data('value', msg.real_total - total).html((msg.real_total - total) + ' ' + window.PROSKTR.currencies.right);
		$('.side-total-cost').data('value', parseInt(total)).html(parseInt(total) + ' ' + window.PROSKTR.currencies.right);

		if ( msg.count_contents > 0 ) {
			$('.not-empty-cart').removeClass('d-none');
			$('.empty-cart').addClass('d-none');
			$('.header-cart').addClass('basket-opener').closest('li').addClass('not-empty');
		} else {
			$('.empty-cart').removeClass('d-none');
			$('.not-empty-cart').addClass('d-none');
			$('.header-cart').removeClass('basket-opener').closest('li').removeClass('not-empty');
		}
		cookieObj.setCookie('customerorderCnt',  msg.count_contents );
		if ( $('#form-get-delivery').length ) {
			$('#form-get-delivery').submit();
		}

		if ( $('.j-cashback-for-order').length ) {
			let cashbackPct = $('.j-cashback-for-order').data('pct') * 1;
			let cashback = Math.round(msg.total * cashbackPct / 100);
			$('.j-cashback-for-order').html(cashback + '&nbsp;' + window.PROSKTR.currencies.right);
		}

		if ( msg.weigthDiscount != undefined && $('.j-cur-b2b-dpct').length ) {
			$('.j-cur-b2b-dpct').text(msg.weigthDiscount);
		}
	}

	productsHtml = $.render['shopping_cart_product'].render(
		msg.products,
		{customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
	);
	$('#checkoutProductsList').html(productsHtml);

	// let hiddenOrder = (msg.currentWhGroup == 'SOVAPROD' || msg.currentWhGroup == 'SOVASPB' || msg.currentWhGroup == 'SOVAMSK') && msg.coffeeWeight < 10000;
	// if ( hiddenOrder ) {
	// 	$('#ordering').addClass('d-none');
	// 	$('#need-more').removeClass('d-none');
	// } else {
	// 	$('#ordering').removeClass('d-none');
	// 	$('#need-more').addClass('d-none');
	// }
	
	let $otherHolder = $('.j-otherGroups');
	$otherHolder.html('');
	let isEmpty = true;
	for ( group in msg.otherWhGroups ) {
		if ( msg.otherWhGroups.hasOwnProperty(group) ) {
			isEmpty = false;
		}
	}
	if ( $('#products-tab').hasClass('b2b-cart') || !isEmpty ) {
		let mainGroup = (msg.currentWhGroup == 'PRO2' ? '����� �� �������� �� ������ ������' : '����� �� ������������') + ' - ������������ ��������';
		if ( msg.currentWhGroup == 'SOVASPB' ) {
			mainGroup = '����� �� �������� �� ������ �����-��������� (� ��������� �� ������) - ������������ ��������';
		}
		if ( msg.currentWhGroup == 'SOVAMSK' ) {
			mainGroup = '����� �� �������� �� ������ ������ (� ��������� �� ������) - ������������ ��������';
		}
		mainGroup += '<div>������� ����� ��� ����������</div>';
		$('.j-firstorder').html(mainGroup).removeClass('d-none');
	} else {
		$('.j-firstorder').addClass('d-none');
		$('.j-main-summary').addClass('d-none');
	}
	if ( !isEmpty ) {
		let mainSummary = (msg.count_contents * 1) + ' ' + NumToRu_declination(msg.count_contents, '�����', '������', '�������') + ' �� ����� <span>' + (msg.total * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</span>';
		$('.j-main-summary').html(mainSummary).removeClass('d-none');

		for ( group in msg.otherWhGroups ) {
			let headDiv = document.createElement('div');
			headDiv.className = 'ordergroup-head';
			let groupTitle = (group == 'PRO2' ? '����� �� �������� �� ������ ������' : '����� �� ������������') + ' - ������������ ��������';
			if ( group == 'SOVASPB' ) {
				groupTitle = '����� �� �������� �� ������ �����-��������� (� ��������� �� ������) - ������������ ��������';
			}
			if ( group == 'SOVAMSK' ) {
				groupTitle = '����� �� �������� �� ������ ������ (� ��������� �� ������) - ������������ ��������';
			}
			headDiv.innerHTML = groupTitle;
			$otherHolder.append(headDiv);

			let groupProductsHtml = $.render['shopping_cart_product'].render(
				msg.otherWhGroups[group],
				{customer: window.PROSKTR.customer, currencies: window.PROSKTR.currencies}
			);
			let entriesDiv = document.createElement('div');
			entriesDiv.className = 'entries';
			entriesDiv.innerHTML = groupProductsHtml;
			$otherHolder.append(entriesDiv);

			let groupCount = 0;
			let groupPrice = 0;
			for (let index = 0; index < msg.otherWhGroups[group].length; index++) {
				const element = msg.otherWhGroups[group][index];
				groupCount += element.quantity * 1;
				groupPrice += element.quantity * element.final_price;
			}

			let totalsDiv = document.createElement('div');
			totalsDiv.className = 'summary';
			let totalsInner = (groupCount * 1) + ' ' + NumToRu_declination(groupCount, '�����', '������', '�������') + ' �� ����� <span>' + (groupPrice * 1) + ' ' + window.PROSKTR.currencies.rightMin + '</span>';
			totalsDiv.innerHTML = totalsInner;
			$otherHolder.append(totalsDiv);
		};
	}
	// initNumberInput();
}

ShoppingCart.prototype.addProductMultipleCallback = function( data ) {
	return function( msg, textStatus, jqXHR ) {
		if ( msg.added_product.length ) {
			window.location.href = '/shopping_cart.php';
		}
	}
}

$.widget("map.shopsMap", $.map.shopsMap, {
	options: {
		iconsPoint: {
			src: '/templates/sova/public/images/icon28.png',
			size: [33, 41],
			offset: [0, -41],
		},
		iconCluster: [
			{
				src: '/templates/sova/public/images/krug_ym.png',
				size: [40, 40],
				// ������, ����� ����� �������� �������� � ������� ��������.
				offset: [-20, -20]
			},
			{
				src: '/templates/sova/public/images/krug_ym.png',
				size: [50, 50],
				offset: [-25, -25]
			}
		],
		mapTabControl: '#map-control',
		mapLoading: '#mapLoading',
	}
});
WishList.prototype.setTotal = function( count ) {
	if( typeof this.options.setTotal == 'function' ) {
		return this.options.setTotal( count );
	}
	$('.wishlist-content-count').text( count );
	if ( count > 0 ) {
		$('.j-top-empty-wishlist-icon').addClass('d-none');
		$('.j-top-not-empty-wishlist-icon').removeClass('d-none');
	} else {
		$('.j-top-empty-wishlist-icon').removeClass('d-none');
		$('.j-top-not-empty-wishlist-icon').addClass('d-none');
	}
};
WishList.prototype.settings.afterAdd = function( products_id, response ) {
}
WishList.prototype.settings.afterRemove = function( products_id, response ) {
	if( PROSKTR.content == 'wishlist' ) {
		$('.j-wish-check[value=' + products_id + ']').closest('.wishlist-product ').slideUp();
	}
}
InfoShops.prototype.settings.iconImageHref = "/templates/comma/images/icon28.png";
InfoShops.prototype.settings.iconImageSize = [33, 41],
InfoShops.prototype.settings.iconImageOffset = [-12, -48];
InfoShops.prototype.updateList = function( list ) {
	if( this.options.hasList ) {
		var arr = [];
		$.each(list, function(key,value) {
			if( value.shop_type == '' ) {
				arr.unshift( value );
			} else {
				arr.push( value );
			}
		});
		this.options.beforeUpdateListCallback.call(this);
		$(this.options.listWraper, this.wraper).html( $.render[ 'shops' ].render( arr ) );
		this.options.afterUpdateListCallback.call(this);
	}
}

$.fn.myHide = function() {
	return $(this).addClass('d-none');
};
$.fn.myShow = function() {
	return $(this).removeClass('d-none');
};